.navContainer {
 


  .dropdown {
    position: fixed;
    background-color:rgba(10, 27, 48, 0.947);
    opacity: 1;
    display: flex;
    height: 100vh;
    width: 15vw;
    align-items: space-between;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    transition: 0.8s;
    
  
    a {
      margin-top: 60%;
      color: white;
      text-decoration: none;
      margin-bottom: 10px;
      padding: 10px;
      width: 100%;

      &:hover {
        color: black;
        background-color: lightgray;
        transition: 0.2s;      
        }
      }
    }
  }
    
  



.burger {
  position: fixed;
  display: inline-block;
  width: 10%vw;
  background-color: none;
  cursor: pointer;
  margin: 20px 40px;
  z-index: 30;


  .bar1 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    box-shadow: 1px 2px 2px black;
  }

  .bar2 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    box-shadow: 1px 2px 2px black;
  }

  .bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    box-shadow: 1px 2px 2px black;
  }

  .changebar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
    background-color: white;
  }

  .changebar2 {
    opacity: 0;
  }
  
  .changebar3 {
    transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
    background-color: white;
  }
}

@media only screen and (max-width: 600px) {
  .navContainer{
   
    .dropdown {
      width: 100vw;
      height: 100vh;
      opacity: 1;
      margin-top: 0;
      display: flex;
      flex-direction: row;
      overflow-y: hidden;
      z-index: 10;
      

      a {
        margin-top: 20%;
        height: 8%;
      }
    } 
  }
}