@import "../../variables.scss";

.header {
  font-family: 'Play', sans-serif;
  font-size: 2.5em;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  padding: 1em;
  text-shadow: 1px 1px 3px black;
}


.aboutContainer {
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0, auto;

  section {
    background-color: rgba(10, 27, 48, 0.947);
    opacity: 0.9;
    color: $primary-color;
    font-family: 'Play', sans-serif;
    font-size: 1.4em;
    height: 80vh;
    width: 60vw;
    border-radius: 10%;
    margin: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 20px white;
    border: solid 2px white;

    .text {
      padding: 4px;
      color: $primary-color;
      margin-left: 4px;
    }

    .icons {
      width: 60%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 8px;
    }
  }
}

@media only screen and (max-width: 678px) {
  .header {
    font-size: 1em;
  }
    .aboutContainer {
      height: unset;
    
      
      section {
        width: 95vw;
        height: unset;
        border-radius: 2%;
        margin-top: 20%;
        justify-content: unset;

      .text {
        font-size: 0.5em;
        padding: 6px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .header {
    font-size: 1.5em;
  }
    .aboutContainer section {
      width: 100vw;
      height: 85vh;
      border-radius: 2%;
      margin-top: 10%;
      justify-content: unset;
      overflow-y: hidden;

    
      .text {
        font-size: 0.7em;
      }
      
    }

}