@import "../../variables.scss";

.cardContainer {
  margin: $base-spacing;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);

  .portfolioCard {
    position: relative;
    width: 320px;
    height: 240px;
    text-align: center;
    max-width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 3;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }

    .portfolioCardText {
      @include flex-column-center;
      width: 100%;
      height: 100%;
      background-color: $secondary-color;
      padding: $base-spacing * 2;
      color: $primary-color;
     

      .text {
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 15%;
        opacity: 0;
        z-index: 8;
        color: $secondary-color;
        background-color: $primary-color;
        width: 100%;

        h3 {
          font-size: 1.5em;
          margin-bottom: 5%;
          padding: 10px;
        }

        p {
          font-size: 1.2em;
          padding: 0 10%;
        }
      }

      .links {
        transition: all 0.3s ease-in-out;
        position: absolute;
        bottom: 0;
        opacity: 0;
        z-index: 7;
        color: $primary-color;
        

        .fontAwesome {
          font-size: 32px;
          margin: $base-spacing;
          color: $primary-color;
          filter:drop-shadow(1px 1px 1px $secondary-color);

          &:hover path {
            color: skyblue;
            transition: all 0.3s ease-in-out;
          }
        }
      }


    }
    
    
    &:hover img {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      box-shadow: 2px 2px 20px white;
      border: solid 2px white;

      button {
        cursor: pointer;
      }
    }

    &:hover .text {
      top: 0%;
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }

    &:hover .languages {
      bottom: 10%;
      visibility: visible;
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }

    &:hover .links {
      bottom: 6%;
      transition: all 0.3s ease-in-out;
      opacity: 1;
      color: skyblue;
    }
  }
}
    
@media only screen and (min-width: 768px) {
  .cardContainer {
    .portfolioCard {
      width: 320px;
      height: 240px;
    }
  }
}

@media only screen and (min-width: 1044px) {
  .cardContainer {
    .portfolioCard {
      width: 340px;
      height: 250px;
    }
  }
}

.slideInLeft {
  -webkit-animation: slideInLeft 0.75s ease-in both;
  animation: slideInLeft 0.75s ease-in both;
}
    
  
