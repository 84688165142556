.namediv {
  
  height: 100vh;
  width: 100vw;
  display: inline-flex;
  color: whitesmoke;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;


  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 3.2em;
    margin: 0;
    margin-top: 80px;
    padding: 2%;
    opacity: 0.9;
    

  }

  h2{
    font-family: "Montserrat", sans-serif;
    font-size: 2em;
    margin: 0;
    padding: 1%;
    opacity: 0.9;
  }

  .fa {
    font-size: 2.3em;
    width: 30vw;
    padding: 3%;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    
    a:link {
      text-decoration: none;
      color: whitesmoke;
    }
    a:visited {
      text-decoration: none;
      color: whitesmoke;
      border-radius: 20%;
      opacity: 0.8;
    }

    a:hover {
      color: black;
      background-color: whitesmoke;
      border-radius: 20%;
      opacity: 0.8;
    }
    
    .icon {
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20%;
      opacity: 0.8;
      text-decoration: none;
    
    }
  }
}

@media only screen and (max-width: 678px) {
  .namediv {
    h1 {
      font-size: 1.7em;
    }

    h2 {
      font-size: 1.4em;
    }
    .fa {
      font-size: 2em;
      justify-content: center;
  }
}
}