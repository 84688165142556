* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.app {
  background-size: cover;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9),  rgba(0, 0, 0, 0.2)), url("./assets/david-becker-crs2vlkSe98-unsplash.jpg");
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  }

  .navContainer {
  width: 20vw;
  max-height: 100vh;
  position: sticky;
  vertical-align: top;
}

  .routesContainer {
  width: 100vw;
  max-height: 100vh;
  display: inline-block;
}

@media only screen and (min-width: 768px) {

  .app {
    background-size: unset;
  }
  .navContainer {
    display: inline-block;
    max-height: 100vh;
    width: 25%;
    vertical-align: top;
    position: sticky;
  }

  .routesContainer {
    display: inline-block;
    width: 100vw;
    height: 100vh;
    vertical-align: top;
  }
}

@media only screen and (min-width: 1044px) {
  .navContainer {
    width: 20%;
  }

  .routesContainer {
    width: 100vw;
    max-height: 100vh;
  }
}

@media only screen and (max-width: 600px) {
  .app {
    flex-direction: column;
    background-image: unset;
    background-color: rgba(10, 27, 48, 0.947);
    background-repeat: repeat-y;
    overflow-y: scroll;
  }
  
  .navContainer {
    width: 100vw;
    position: fixed;
  }
}