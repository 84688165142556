@import "../../variables.scss";

.container {
  width: 100vw;
  height: 80vh;
  justify-content: center;

  .header {
    font-family: 'Play', sans-serif;
    font-size: 2.5em;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    padding: 1em;
    text-shadow: 1px 1px 3px black;
    z-index: 0;
  }

  .workContainer {
    height: 80vh;
    width: 70vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5vh;
    margin: 0 auto;

   
    
  }
}  

@media only screen and (max-width: 600px) {
  .container {
    height: 100vh;
  }
}
